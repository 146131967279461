.settings {
    position: fixed;
    top: rem(20px);
    right: rem(20px);
    z-index: 1000;
    transform: rotate(0deg);
    transition: transform .2s;

    &:hover {
        transform: rotate(180deg);
    }

    svg {
        width: rem(20px);
        height: rem(20px);
    }
}
