#root {
    display: flex;
    min-height: 100%;
}

.ant-layout-header {
    position: sticky;
    top: 0;
    background-color: var(--app-light-color);
    box-shadow: 0 0 0 0 rgba(#000, .0);
    transition: box-shadow .3s ease-out;
    z-index: 1000;

    &.ant-layout-header--scroll {
        box-shadow: 0 11px 6px -8px rgba(#000, .18);
    }
}

.ant-layout {
    background-color: var(--app-light-color);
}

.ant-layout-content {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.ant-layout-footer {
    position: sticky;
    bottom: 0;
    background-color: var(--app-backgorund-color);
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
