.card {
    height: rem(70px);
    width: rem(50px);

    &--hidden {
        transform: rotateY(180deg);
    }

    &--disabled {
        .card__front {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%23d5d5d5' d='M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: rem(25px);
            background-color: #f1f1f1;
            border-style: dashed;
        }
    }

    align-items: center;
    border: 0;
    cursor: default;
    display: flex;
    font-size: rem(20px);
    justify-content: center;
    outline: 0;
    position: relative;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    border-radius: rem(7px);
    box-shadow: rem(0 4px 8px 0) rgba(0,0,0,0.2);
    transition: transform .6s;

    &__front {
        &.card__front--voted {
            background-color: #f3f3f3;
            background-image: linear-gradient(135deg, rgba(#009EE0, .6) 12.5%, rgba(#009EE0, .2) 12.5%, rgba(#009EE0, .2) 37.5%, rgba(#009EE0, .6) 37.5%, rgba(#009EE0, .6) 62.5%, rgba(#009EE0, .2) 62.5%, rgba(#009EE0, .2) 87.5%, rgba(#009EE0, .6) 87.5%);
            background-size: rem(20px 20px);
            background-position: rem(30px 30px);
            border-color: #009EE0;
        }

        background-color: #f3f3f3;
        background-image: linear-gradient(135deg, #dfdfdf 12.5%, #fff 12.5%, #fff 37.5%, #dfdfdf 37.5%, #dfdfdf 62.5%, #fff 62.5%, #fff 87.5%, #dfdfdf 87.5%);
        background-size: rem(20px 20px);
        background-position: rem(30px 30px);

        transform: rotateY(180deg);
        border: rem(2px) solid #d5d5d5;
        z-index: 5;
        backface-visibility: hidden;
    }
    &__back {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: #fff;
        border: rem(2px) solid #000;
        z-index: 1;
    }

    &__front,
    &__back {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: rem(7px);
        backface-visibility: hidden;
        transition: border .3s, background-color .3s;
    }
}
