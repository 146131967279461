@import "./Mixins/rem-utility";

:root {
    --app-backgorund-color: #f0f2f5;
    --app-light-color: #ffffff;
}

body {
    font-size: rem(16px);
    background-color: var(--app-backgorund-color);
}

@import "./Modules/layout";
@import "./Modules/pokertable";
@import "./Modules/card";
@import "./Modules/settings";
